// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-business-bookkeeping-services-js": () => import("./../../../src/pages/business-bookkeeping-services.js" /* webpackChunkName: "component---src-pages-business-bookkeeping-services-js" */),
  "component---src-pages-financial-reporting-services-js": () => import("./../../../src/pages/financial-reporting-services.js" /* webpackChunkName: "component---src-pages-financial-reporting-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personal-bookkeeping-services-js": () => import("./../../../src/pages/personal-bookkeeping-services.js" /* webpackChunkName: "component---src-pages-personal-bookkeeping-services-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-small-business-management-services-js": () => import("./../../../src/pages/small-business-management-services.js" /* webpackChunkName: "component---src-pages-small-business-management-services-js" */)
}

